<template>
    <div class="flex flex-column bg-light flex-fill">

        <surround-loader v-if="token != null" :is-loading="isLoading" class="flex-wrap bg-white p-3">
            <div class="p-4">

                <router-link :to="{name : 'home'}"><img class="mb-4" src="../../assets/logo.png" style="width: 220px"/></router-link>
                <div v-if="linkError">
                    <div class="alert alert-danger">
                        This link is not valid or has expired.
                    </div>
                    <b-button @click="() => { this.$router.replace('/register').catch((err) => {}); }"
                              class="mt-4 text-left d-flex align-items-center"
                              variant="outline-danger">
                        <span>Restart registration</span>
                    </b-button>
                </div>

                <div v-if="serverError">
                    <div class="alert alert-danger">
                        Sorry, there's been an error processing your request. Please try again.
                    </div>
                    <b-button @click="loadToken"
                              class="mt-4 text-left d-flex align-items-center"
                              variant="outline-danger">
                        <span>Retry</span>
                    </b-button>
                </div>
            </div>

        </surround-loader>

        <surround-loader v-else :is-loading="isLoading" class="flex-sm-wrap flex-fill bg-white p-3">
            <div class="p-4">
                <router-link :to="{name : 'home'}"><img class="mb-4" src="../../assets/logo.png" style="width: 220px"/></router-link>
                <div>
                    <b-alert show variant="info">
                        <b-icon icon="info-circle"></b-icon>
                        <span>
                            Our Right to Work App has now been seamlessly integrated into our comprehensive, automated background screening solution. 
                            This means you can efficiently run right-to-work checks alongside all your other pre- and post-employment screening needs.
                        </span>
                        <br />
                        <span>
                            If you're looking for a right-to-work solution, <a href='https://www.theaccessgroup.com/en-gb/recruitment/form/screening/demo/' target="_blank">simply fill out this form</a> or give us a call at 0845 345 3300.
                        </span>
                    </b-alert>
                </div>

                <div class="mt-4" style="font-size: 14px;">
                    Already have an account? <a href="#" @click="login">Login here.</a>
                </div>
            </div>

        </surround-loader>


        <div class="col p-5 d-flex justify-content-center flex-fill">
            <div class="flex-wrap">
                <product-features></product-features>
            </div>
        </div>

    </div>
</template>

<script>
    import {containsErrors, isEmail} from "../../modules/helpers/helpers";
    import {ssoCheck, login, getResource, saveResource} from "../../modules/api/methods";
    import {ssoAuthorize, ssoAuthorizeHint, register, userValidate} from "../../modules/api/endpoints";
    import ErrorDisplay from "../../components/form_inputs/ErrorDisplay";
    import SurroundLoader from "../../components/SurroundLoader";
    import ProductFeatures from "../../components/marketing_content/ProductFeatures";

    export default {
        name: 'register',
        data: function () {
            return {
                isLoading: false,
                emailSent: false,
                serverError: false,
                linkError: false,
                errors: {},
                user: {}
            }
        },
        props: {
            token: null,
        },
        created() {
            if (this.token != null) {
                //LOG OUT OF ANY OTHER INSTANCES AS CAME FROM EMAIL LINK
                this.$store.commit('logout');
                this.loadToken();
            } else {



            }
        },
        mounted() {
            if (this.token == null) {
                this.$refs.fullname.focus();
            }
        },
        components: {
            ProductFeatures,
            SurroundLoader,
            ErrorDisplay
        },
        methods: {
            login: function () {
                this.isLoading = true;
                window.location = ssoAuthorizeHint("");
            },
            loadToken: function () {
                this.isLoading = true;
                this.linkError = false;
                this.serverError = false;
                getResource(userValidate(this.token)).then((resp) => {
                    if (resp.data.success.data.link !== undefined) {
                        window.location = resp.data.success.data.link;
                    } else {
                        this.linkError = true;
                    }
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.linkError = true;
                    } else {
                        this.serverError = true;
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
        },
    }
</script>
